import React from "react";
import { Link } from "gatsby";
import Layout from "../Components/Layout";
import { ArrowAtgal } from "../Components/Buttons";

function Error() {
  return (
    <Layout>
    <div className="Rules">
    <div className="rulesNav">
        <Link to="/" aria-label="Grįžti atgal į pagrindinį puslapį">
          <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
      </div>
      <div className="rulesMain" style={{marginTop:"20px"}}>
        <h3>404. Tokio puslapio nėra <span role="img" aria-label="liudnas emoji">&#128532;</span></h3>
            <p id="body" style={{textAlign:"center"}}>
                Turbūt ne ten pataikėte
            </p>
      </div>
    </div>
    </Layout>
  );
}

export default Error;